var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "px-2" },
    [
      _c("b-col", { attrs: { cols: "12" } }, [
        _c("h4", { staticClass: "primary fw-bold" }, [
          _vm._v("Valida tu identidad")
        ]),
        _c("p", { staticClass: "mt-3 fs-14", attrs: { align: "justify" } }, [
          _vm._v(
            " Por favor responde las siguientes preguntas para validar tu identidad. Selecciona las respuestas que consideres correctas y haz clic en el botón aceptar para continuar. "
          )
        ])
      ]),
      _vm._l(_vm.$v.preguntas.$each.$iter, function(pregunta, index) {
        return _c(
          "b-col",
          {
            key: pregunta.codigo.$model,
            staticClass: "pt-2",
            attrs: { cols: "12" }
          },
          [
            _c("p", { staticClass: "primary fw-bold mb-1" }, [
              _vm._v("Pregunta " + _vm._s(parseInt(index) + 1))
            ]),
            _c(
              "b-form-group",
              { attrs: { label: pregunta.pregunta.$model } },
              [
                _c(
                  "b-form-select",
                  {
                    attrs: {
                      state: pregunta.valor.$dirty
                        ? !pregunta.valor.$error
                        : null
                    },
                    model: {
                      value: pregunta.valor.$model,
                      callback: function($$v) {
                        _vm.$set(pregunta.valor, "$model", $$v)
                      },
                      expression: "pregunta.valor.$model"
                    }
                  },
                  [
                    _c("b-form-select-option", { attrs: { value: "" } }, [
                      _vm._v("Seleccione")
                    ]),
                    _vm._l(pregunta.respuestas.$model, function(item, idx) {
                      return _c(
                        "b-form-select-option",
                        { key: idx, attrs: { value: item.idRespuesta } },
                        [_vm._v(" " + _vm._s(item.respuesta) + " ")]
                      )
                    })
                  ],
                  2
                ),
                _c("b-form-invalid-feedback", [
                  _vm._v(_vm._s(_vm.validarCampo(pregunta.valor)))
                ])
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "b-col",
        {
          staticClass: "pt-3 d-flex justify-content-end",
          attrs: { cols: "12" }
        },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "danger" },
              on: { click: _vm.validarPreguntas }
            },
            [
              _c("i", { staticClass: "las la-check-circle" }),
              _vm._v(" Aceptar ")
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }