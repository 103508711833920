import { render, staticRenderFns } from "./Registro.vue?vue&type=template&id=19b11d48&"
import script from "./Registro.vue?vue&type=script&lang=js&"
export * from "./Registro.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\PROYECTOS TRABAJO\\2 VUE\\sucursal-vitual-releases\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('19b11d48')) {
      api.createRecord('19b11d48', component.options)
    } else {
      api.reload('19b11d48', component.options)
    }
    module.hot.accept("./Registro.vue?vue&type=template&id=19b11d48&", function () {
      api.rerender('19b11d48', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/modules/auth/views/Registro.vue"
export default component.exports